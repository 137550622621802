import React from "react";

const Home = () => {
	return (
		<div>
			<h1>Page d'accueil</h1>
			<p>Bienvenue sur la page d'accueil !</p>
		</div>
	);
};

export default Home;
