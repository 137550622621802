import React, { useState } from "react";
import scales from "../data/scales.json";

const Scale = () => {
	// État pour la gamme sélectionnée
	const [selectedScale, setSelectedScale] = useState(scales[0]);

	// Handler pour changer la gamme
	const handleScaleChange = (event) => {
		const scaleId = event.target.value;
		const scale = scales.find((g) => g.id === scaleId);
		setSelectedScale(scale);
	};

	return (
		<div className="App">
			<h1>
				<span>Intervalles des principales</span>
				Gammes & modes
			</h1>
			<div className="scale-selector">
				<label htmlFor="gammes">Choisir une gamme ou un mode : </label>
				<select id="gammes" onChange={handleScaleChange} value={selectedScale.id}>
					{scales.map((scale) => (
						<option key={scale.id} value={scale.id}>
							{scale.name}
						</option>
					))}
				</select>
			</div>
			<div className="scale-ctn">
				<ul className="scale-render">
					{Array.from({ length: 13 }).map((_, index) => {
						const interval = selectedScale.intervals.find((_, i) => selectedScale.positions[i] === index);
						return (
							<li className={`scale-render__item${interval === "T" ? " is-root" : ""}`} key={index}>
								{interval || ""}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default Scale;
