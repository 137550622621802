import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
	const location = useLocation();

	const isActive = (path) => (location.pathname === path ? "is-active" : "");
	return (
		<nav className="navbar">
			<ul>
				<li className={isActive("/")}>
					<Link to="/">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
							<path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
							<path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
						</svg>
					</Link>
				</li>
				<li className={isActive("/scale")}>
					<Link to="/scale">Scale</Link>
				</li>
				<li className={isActive("/scale-training")}>
					<Link to="/scale-training">Scale Training</Link>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
