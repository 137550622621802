import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Scale from "./pages/Scale";
import ScaleTraining from "./pages/ScaleTraining";

import "./App.css";
import Navbar from "./components/Navbar";

function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				{/* Définir vos routes */}
				<Route path="/" element={<Home />} />
				<Route path="/scale" element={<Scale />} />
				<Route path="/scale-training" element={<ScaleTraining />} />
			</Routes>
		</Router>
	);
}

export default App;
