import React, { useState } from "react";
const ScaleTraining = () => {
	const notes = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

	const scales = [
		{ id: "blues", name: "Blues mineure" },
		{ id: "pentaMineure", name: "Pentatonique mineure" },
		{ id: "pentaMajeur", name: "Pentatonique majeure" },
	];

	const positions = [1, 2, 3, 4, 5]; // Positions universelles

	// État pour la Scale sélectionnée
	const [selectedScale, setSelectedScale] = useState(scales[0].id);
	const [result, setResult] = useState("");

	const handleClick = () => {
		// Trouver la Scale sélectionnée
		const currentScale = scales.find((scale) => scale.id === selectedScale);

		if (!currentScale) {
			setResult("Aucune gamme sélectionnée.");
			return;
		}

		// Sélectionner une note aléatoire
		const randomNote = notes[Math.floor(Math.random() * notes.length)];

		// Sélectionner une position aléatoire (1 à 5)
		const randomPosition = positions[Math.floor(Math.random() * positions.length)];

		// Générer un contenu HTML pour le résultat
		const formattedResult = `
		<span>${randomNote}</span>
		<span>${currentScale.name}</span>
		<span>Position ${randomPosition}</span>`;

		setResult(formattedResult);
	};

	return (
		<div className="App">
			<h1>
				<span>Exercice de position</span>
				Gammes
			</h1>
			<div className="scale-selector">
				<label htmlFor="scale-selector">Choisissez une gamme :</label>
				<select value={selectedScale} onChange={(e) => setSelectedScale(e.target.value)} id="scale-selector">
					{scales.map((scale) => (
						<option key={scale.id} value={scale.id}>
							{scale.name}
						</option>
					))}
				</select>
			</div>
			<div className="scale-training">
				<div className="scale-training__result" dangerouslySetInnerHTML={{ __html: result }}></div>
				<button onClick={handleClick}>
					<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round">
						<rect width="12" height="12" x="2" y="10" rx="2" ry="2" />
						<path d="m17.92 14 3.5-3.5a2.24 2.24 0 0 0 0-3l-5-4.92a2.24 2.24 0 0 0-3 0L10 6" />
						<path d="M6 18h.01" />
						<path d="M10 14h.01" />
						<path d="M15 6h.01" />
						<path d="M18 9h.01" />
					</svg>
				</button>
			</div>
		</div>
	);
};

export default ScaleTraining;
